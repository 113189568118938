<template>
    <div>
        <div class="img-performance" id="img-performance">
            <div>
                <v-row >
                    <v-col cols="12" sm="9" class="text-home">
                        <h1 class="big-title" >Surveillez vos performances à tout moment avec BioTag Performance</h1>
                        
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-row>
             <v-col cols="12" sm="1" md="1" lg="1"></v-col>
            <v-col cols="12" sm="10" md="4" lg="4" class="mt50 pd5" >
                <div id="le-biotagsensor" class="trais"></div>
                <h1 class="green-title">Le biotag performance</h1>
                <p class="text-classi"> <br> L’implant BioTagSensor Performance est une solution robuste et fiable pour le suivi précis des constantes physiologiques et comportementales ! <br>
La conception de l’implant est particulièrement orientée aux contraintes des environnements et conditions les plus exigeants comme les sportifs (amateurs ou de haut niveaux), les pompiers, les militaires, les plongeurs (…) <br><br>

La détection par l’implant d’une situation à risques (cœur, respiration, détresse physique, blessure …) dès la première alerte sur les signes vitaux et le partage externe de ces données à un collègue, un état-major, un responsable permet alors de gérer au plus tôt cette situation exceptionnelle.
<br><br>
Le mesure du pH et des constantes donne une bonne indication sur le suivi de la fatigue et du stress musculaire.
                </p>
                <div class="btn-loc">
                    <router-link to="/contact" id="btn" >Contactez-nous</router-link>
                </div>
            </v-col>
            <v-col cols="12" sm="1" md="4" lg="2"></v-col>
            <v-col cols="12" sm="10" md="4" lg="4">
                <div class="img-implant2">
                    <img src="../images/GlassTag.png" alt="cage équipée">
                </div>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col cols="12" sm="1" lg="1"></v-col>
            <v-col cols="12" sm="10" lg="10">
                <div class="trais-vr"></div>
                <h1 class="green-title mt50">Exemples d’applications </h1>
                <div class="applications">
                    <div>
                        <img src="../images/militaires.jpg" alt="medecin derrière son ordi">
                        <h3>Diagnostic en temps réel</h3>
                        <p>Dans une situation à haut risque, la connaissance au plus tôt de la blessure ou la détresse d’une personne, isolée ou en groupe, permet une réponse la plus rapide.</p>
                    </div>
                    <div>
                        <img src="../images/courreur.jpg" alt="application ciblée d'un pansement">
                        <h3>Mesure de la performance</h3>
                        <p>L’implant donne au sportif de tout niveau les mesures essentielles au suivi de ses performances sans le contraindre dans ses mouvements.</p>
                    </div>
                    <div>
                        <img src="../images/pompier.png" alt="travail">
                        <h3>Suivi continu des constantes</h3>
                        <p>La mesure continue des constantes  physiologiques et comportementales par l’implant permet de détecter un futur problème avant les effets sur le corps et ainsi d’agir en conséquence.</p>
                    </div>
                </div>
            </v-col>
        </v-row>
         <v-row >
            
            <v-col cols="12" sm="1" lg="1"></v-col>
            <v-col cols="12" sm="10" lg="4" class="mt50 pd5">
                <h1 class="green-title">Collaboration par l’implant plateforme</h1>
                <p style="text-align: justify" class="p-collaboration"> Notre Implant Plateforme donne la possibilité d’intégrer de nouveaux capteurs ou solutions.
Vous êtes porteur d’une solution ou avez un savoir-faire spécifique ? Travaillons ensemble à la réussite de votre projet implantable !
</p>
            </v-col>
            <v-col cols="12" sm="1"></v-col>
            <v-col cols="12" sm="10" lg="4" class="mt50 pd5">
                <div class="engrenages">
                    <img src="../images/poignée.jpg" alt="engrenages">
                </div>
            </v-col>
            <v-col cols="12" sm="1" lg="1"></v-col>
        </v-row>
        <foot/>
    </div>
</template>

<script>
import foot from '@/components/Footer.vue'
export default {
    components:{
      foot
    },
}
</script>

<style scoped>

.img-performance{
    background-size: cover;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    text-align: left;
    position: relative;
}

#img-performance{
    background-image: url(../images/montage_performance.jpg);
}

.img-performance::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Ajout du filtre opaque noir */
    background-color: rgba(0, 55, 109, 0.33);
}
.text-home {
    position: relative;
    top: 150px;
  }
.text-home h1{
    margin-left: 5%;
    width: 90%;
    margin-bottom: 5px;
}



.img-implant2{
    margin-top: 200px;
    display: flex;
    justify-content: center;
}

.img-implant2 img{
    width: 100%;
    transition: transform 0.5s ease-in-out;
}


.img-implant img.animated {
  transform-origin: center;
  transition: transform 0.5s ease-in-out;
}

.fonctionnement{
    display: flex;
    justify-content: space-around;
    
}





.engrenages{
    width: 100%;
    overflow: hidden;
    height: 370px;
    border-radius: 20px;
}

.engrenages img{
    width: 100%;
}

.newsletter{
    background-color: var(--vertgris);
    justify-content: center;
    text-align: center;
}

.newsletter h2{
    margin-top: 25px;
    color: var(--violet);
    font-family: 'roboto' sans-serif;
    font-size: 35px;
    font-weight: 300;
    text-transform: uppercase;
    width: 100%;
}

.newsletter p{
    margin-top: 25px;
    color: var(--violet);
    font-family: 'roboto' sans-serif;
    font-size: 20px;
    font-weight: 300;
}

.form{
    margin-left: 15%;
    margin-bottom: 50px;
    margin-top: 50px;
    width: 70%;
}

.form input{
    width: 55%;
    border: solid 1px var(--violet);
    background-color: transparent;
    height: 35px;
    margin-right: 5%;
}


.p-collaboration{
    font-family: 'Roboto',  sans-serif;
    font-size: 1.3rem;
}

@media (max-width: 1264px) {

.text-home{
    text-align: justify;
    margin-top: 50px;
}
.data-mesurées{
    margin-left: 5%;
    width: 90%;
}

.img-performance{
    margin-top: 130px;
    height: 80vh;
}

.img-implant{
    margin-top: 50px;
}

.avantages{
    margin-left: 5%;
    width: 90%;
}
.applications div{
    width: 45%;
}

.fonctionnement{
    display: block;
}



.applications{
    display: block;
}

.applications div{
    width: 100%;
}
.p-collaboration{
    font-size: 1rem;
}
}

@media (max-width: 765px) {


.applications{
    display: block;
}

.applications div{
    width: 100%;
}
}


</style>

